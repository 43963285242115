window.GRGExtensions = {
    minimumStartBookDays: 7,
    maximumStartBookDays: 30,
    defaultSearchEndDays: 6 * 30, //6 months if no end date given
    bindRangeSlider: function (sliderItemIdentifier, sliderOptions, options, defaultValues) {
        /*
        Text identifier we are binding on,
        JQuery element fromInput,
        JQuery element toInput,
        sliderOptions,
        options: {
            //if used requires 1 parameter, callback must accept 1 parameter
            updatedCallbackFunction: updatedCallbackFunction
        }

        */
        options = options || null;
        var $sliderEle = null;

        //bind instance
        function bindSlider() {
            if ($sliderEle === null) {
                // create the slider
                console.log("binding slider: " + sliderItemIdentifier);
                $sliderEle = new Slider(sliderItemIdentifier, sliderOptions);

                $sliderEle.on("slideStop",
                    function (data) {
                        // set the inital values
                        var initialValues = $sliderEle.getValue();
                        //setInputValues(data);
                        //currentVal = data;
                        //console.log(data);
                        console.log("ON CHANGE: " + sliderItemIdentifier);
                        console.log(initialValues);
                        console.log(options);
                        if (options !== null && typeof (options.updatedCallbackFunction) !== 'undefined' &&
                            options.updatedCallbackFunction !== null) {
                            console.log("updating callback function 1");
                            options.updatedCallbackFunction([initialValues[0], initialValues[1]]);
                        }

                        if (options !== null && typeof (options.isSingleValue) !== 'undefined' &&
                            options.isSingleValue !== null) {

                            if (options.isSingleValue) {
                                options.updatedCallbackFunction([0, initialValues]);
                            } else {
                                options.updatedCallbackFunction(initialValues);
                            }
                        }
                    }
                );
            }
            else {
                //rerender
                $sliderEle.resize();
            }
        }
        bindSlider();
        return {
            sliderElement: $sliderEle,
            resetValues: function () {
                //reset when cleared
                //var currentVal = { newValue: [0, 100] };
                $sliderEle.setValue(defaultValues, true);

                if (options !== null && typeof (options.setDirtyCallbackFunction) !== 'undefined' &&
                    options.setDirtyCallbackFunction !== null) {
                    options.setDirtyCallbackFunction();
                }
            }

        };
    },
    formatShortCurrency: function (input) {
        return "$" + input.toFixed(0);
    },
    calculate12HoursFrom24Hours: function (input) {
        var calced = "";
        var hour = "";
        var minutes = "";
        var seconds = ""; //ignore
        var amppm = "am";
        //split :
        var timePieces = input.split(":");

        if (timePieces[0] * 1 > 12){
            hour = timePieces[0] * 1 - 12;
            amppm = "pm";
        }
        else {
            hour = timePieces[0] * 1;
        }
        //mins
        minutes = timePieces[1];
        if (minutes.length === 1) {
            minutes = minutes + "0";
        }

        return hour + ":" + minutes + amppm;
    },
    GoogleMapsHelper: {
         //Must not be a .svg or IE11 will throw up
         createMarker: function (width, height, title, imgMarker, fontStyle, position) {
            var canvas, context, radius = 4;
            var dataUrl = "";
            canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            context = canvas.getContext("2d");
            context.clearRect(0, 0, width, height);
            context.drawImage(imgMarker, 0, 0, width, height);
            context.font = fontStyle; //"bold 10pt Arial"
            context.textAlign = "center";
            context.fillStyle = "rgb(255,255,255)";
            context.fillText(title, position[0], position[1]);
            dataUrl = canvas.toDataURL("image/png");
            return dataUrl;
        },
        zoomToMiles: function (zoom) {
            //values for 800px x 800px window
            var radiusMileKeys = [
                [20, 0.0837901798261496],
                [19, 0.16776417862393525],
                [18, 0.33552835213810533],
                [17, 0.6710567126564838],
                [16, 1.3421134197638334],
                [15, 2.6842268204119253],
                [14, 5.3684534872497265],
                [13, 10.736905740071133],
                [12, 21.473801606431724],
                [11, 42.94752422441664],
                [10, 85.77344290256586],
                [9, 171.7985926417136],
                [8, 343.4563966079052],
                [7, 685.6814737885826],
                [6, 1366.614350557506],
                [5, 2700.2763695609374],
                [4, 5230.647443560195],
                [3, 9078.639932879161],
                [2, 10919.753416970045],
                [1, 12285.05300779707]
            ];
            for (var i = 0; i < radiusMileKeys.length; i++) {
                //[0,1]
                if (radiusMileKeys[i][0] === zoom) {
                    //
                    return radiusMileKeys[i][1];
                }
            }
        },
        calculateDistance: function (lat1, lon1, lat2, lon2, unit) {
            var radlat1 = Math.PI * lat1 / 180;
            var radlat2 = Math.PI * lat2 / 180;
            var theta = lon1 - lon2;
            var radtheta = Math.PI * theta / 180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit === "K") { dist = dist * 1.609344; }
            if (unit === "N") { dist = dist * 0.8684; }
            return dist;
        }
    },
    EventHelper: {
        fireOnMapsLoaded: function (callback) {
            if (google_map_loaded) {
                callback();
            }
            else {
                window.GRGEvents.SIG_GoogleMapsLoaded.add(callback);
            }
        }
    },
    BindLocationsAutocomplete: function () {
        var $locationHolder = $("form.form-location-search");
        $locationHolder.each(function () {
            var $currentLocationInput = $(this).find("input.text-location");
            var $stateInput = $(this).find("select.location-state");
            $currentLocationInput.autocomplete({
                source: "/umbraco/surface/locationssurface/autocompletecity/?country=us",
                minLength: 2,
                select: function (e, ui) {
                    //console.log(ui);
                    $stateInput.val(ui.item.state);
                    window.location = ui.item.url;
                }
            });
        });
    },
    BindGoogleAutocomplete: function () {
        var $locationHolder = $("form.form-location-search");
        $locationHolder.each(function () {
            var $currentLocationInput = $(this).find("input.text-location");
            var $parentHolder = $currentLocationInput.closest(".text-location-holder");

            var options = {
                //types: ['geocode'] //this should work !
                types: ['(cities)'],
                //types: ['geocode'],
                componentRestrictions: { country: googleAutocompleteCountryRestrictions } //["us", "ca", "uk"]
            };
            var autocomplete = new google.maps.places.Autocomplete($currentLocationInput[0], options);

            $currentLocationInput.keyup(function () {
                //$(this).removeAttr("data-lat");
                //$(this).removeAttr("data-lon");
            });
            $currentLocationInput.blur(function () {
                if ($parentHolder.length > 0) {
                    if ($currentLocationInput.val() === "")
                    { $parentHolder.removeClass("selected"); }
                    else {
                        $parentHolder.addClass("selected");
                    }
                }
            });

            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var place = autocomplete.getPlace();
                console.log(place);
                if (place !== null && place.geometry !== null) {
                    //$currentLocationInput.attr("data-lat", place.geometry.location.lat());
                    //$currentLocationInput.attr("data-lon", place.geometry.location.lng());
                    

                    if ($parentHolder.length > 0)
                    { $parentHolder.addClass("selected"); }

                    //selected, go there NOW
                    var searchUrl = window.GRGExtensions.searchUrlHelper.rebuildUrlFriendlyLocationName($currentLocationInput.val());
                    window.location.href = searchUrl;
                }
                else {
                    $currentInput.removeAttr("data-lat");
                    $currentInput.removeAttr("data-lon");
                    if ($parentHolder.length > 0)
                    { $parentHolder.removeClass("selected"); }
                }
            });
        });
    },
    getTodaysDate: function(){
        var today = new Date();
        var dd = today.getDate();

        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }
        //today = mm + '-' + dd + '-' + yyyy;
        //console.log(today);
        today = mm + '/' + dd + '/' + yyyy;
        //console.log(today);
        //today = dd + '-' + mm + '-' + yyyy;
        //console.log(today);
        //today = dd + '/' + mm + '/' + yyyy;
        return today;
    },
    getTomorrowsDate: function () {
        var today = new Date();
        today.setDate(today.getDate() + 1);

        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        today = mm + '/' + dd + '/' + yyyy;
        return today;
    },
    getDateFromString: function (date_string) {
        var myDate = null;
        //console.log("getting date from string: " + date_string);
        if (date_string !== null && date_string !== "" && date_string.indexOf("/") > -1) {
            //split
            var parts = date_string.split('/');
            //console.log("splitting");
            //console.log(parts);
            myDate = new Date(parts[2] * 1, parts[0] - 1, parts[1]);
            //console.log(myDate);
        }
        return myDate;
    },
    getDateStringFromDate: function (date_obj) {
        var returnString = "";
        if (date_obj !== null) {
            var year = date_obj.getFullYear();
            // months and days are inserted into the array in the form, e.g "01/01/2009", but here the format is "1/1/2009"
            var month = window.GRGExtensions.padNumber(date_obj.getMonth() + 1);
            var day = window.GRGExtensions.padNumber(date_obj.getDate());
            returnString = month + "/" + day + "/" + year;
        }
        return returnString;
    },
    padNumber: function (number) {
        var ret = new String(number);
        if (ret.length === 1) ret = "0" + ret;
        return ret;
    },
    getMinimumStartDate: function (returnAsString) {
        returnAsString = returnAsString || false;
        //get min start date
        var minStartDate = new Date();
        minStartDate.setDate(minStartDate.getDate() + window.GRGExtensions.minimumStartBookDays);

        if (returnAsString) {
            return window.GRGExtensions.getDateStringFromDate(minStartDate);
        }
        return minStartDate;
    },
    getMaximumStartDate: function (returnAsString) {
        returnAsString = returnAsString || false;
        //get max start date
        var maxStartDate = new Date();
        maxStartDate.setDate(maxStartDate.getDate() + window.GRGExtensions.maximumStartBookDays);
        if (returnAsString) {
            return window.GRGExtensions.getDateStringFromDate(maxStartDate);
        }
        return maxStartDate;
    },
    getDefaultEndDate: function (startDateString, returnAsString) {
        returnAsString = returnAsString || false;
        //get max start date
        var defaultEndDate = window.GRGExtensions.getDateFromString(startDateString);
        defaultEndDate.setDate(defaultEndDate.getDate() + window.GRGExtensions.defaultSearchEndDays);
        if (returnAsString) {
            return window.GRGExtensions.getDateStringFromDate(defaultEndDate);
        }
        return defaultEndDate;
    },
    searchUrlHelper: {
        //rebuild url into the format that we want
        rebuildUrlFriendlyLocationName: function (given_location) {
            var searchUrl = "";

            //split into city, state, country. Discard country for now.
            var searchSegments = given_location.split(',');
            searchUrl = "/locations/";
            var state = "";
            var country = "us";
            var city = "";
            
            if (searchSegments.length > 1) {
                //has third section, country?
                if (searchSegments.length >= 3) {
                    
                    country = searchSegments[2];
                    city = searchSegments[0];
                    state = searchSegments[1];
                }
                else {
                    //no country?
                    if (window.GRGExtensions.searchUrlHelper.replaceProvinceAbbreviations(state) === "usa") {
                        city = searchSegments[0];
                        state = searchSegments[1];
                    }
                    else {
                        city = searchSegments[0];
                        country = searchSegments[1];
                    }
                    
                }

                //preformat country
                country = window.GRGExtensions.searchUrlHelper.getUrlFriendlyLocationName(country).toLowerCase();

                if (country === "usa") {
                    country = "us";
                }

                //any countries, redirect them
                for (var i = 0; i < googleAutocompleteCountryRedirectMapValue.length; i++) {
                    for (var j = 0; j < googleAutocompleteCountryRedirectMapValue[i].length; j++) {
                        if (country === googleAutocompleteCountryRedirectMapValue[i][0]) {
                            country = googleAutocompleteCountryRedirectMapValue[i][1];
                        }
                    }
                }
                
                //us only, pass state with request
                if (country === "us") {
                    state = window.GRGExtensions.searchUrlHelper.replaceProvinceAbbreviations(state);
                    searchUrl += country + "/" + window.GRGExtensions.searchUrlHelper.getUrlFriendlyLocationName(state) + "/" + window.GRGExtensions.searchUrlHelper.getUrlFriendlyLocationName(city) + "/";
                }
                else {
                    searchUrl += country + "/" + window.GRGExtensions.searchUrlHelper.getUrlFriendlyLocationName(city) + "/";
                }
                
            }
            //alert(given_location + ": " + searchUrl);
            
            return searchUrl;
        },
        replaceProvinceAbbreviations: function (provinceAbbreviation) {
            var provinceAbbreviationUppercase = provinceAbbreviation.trim().toUpperCase();
            var stateName = provinceAbbreviation;
            //console.log("provinceAbbreviationUppercase: ", provinceAbbreviationUppercase);
            switch (provinceAbbreviationUppercase) {
                case "AL": stateName = "Alabama"; break;
                case "AK": stateName = "Alaska"; break;
                case "AZ": stateName = "Arizona"; break;
                case "AR": stateName = "Arkansas"; break;
                case "CA": stateName = "California"; break;
                case "CO": stateName = "Colorado"; break;
                case "CT": stateName = "Connecticut"; break;
                case "DE": stateName = "Delaware"; break;
                case "FL": stateName = "Florida"; break;
                case "GA": stateName = "Georgia"; break;
                case "HI": stateName = "Hawaii"; break;
                case "ID": stateName = "Idaho"; break;
                case "IL": stateName = "Illinois"; break;
                case "IN": stateName = "Indiana"; break;
                case "IA": stateName = "Iowa"; break;
                case "KS": stateName = "Kansas"; break;
                case "KY": stateName = "Kentucky"; break;
                case "LA": stateName = "Louisiana"; break;
                case "ME": stateName = "Maine"; break;
                case "MD": stateName = "Maryland"; break;
                case "MA": stateName = "Massachusetts"; break;
                case "MI": stateName = "Michigan"; break;
                case "MN": stateName = "Minnesota"; break;
                case "MS": stateName = "Mississippi"; break;
                case "MO": stateName = "Missouri"; break;
                case "MT": stateName = "Montana"; break;
                case "NE": stateName = "Nebraska"; break;
                case "NV": stateName = "Nevada"; break;
                case "NH": stateName = "New Hampshire"; break;
                case "NJ": stateName = "New Jersey"; break;
                case "NM": stateName = "New Mexico"; break;
                case "NY": stateName = "New York"; break;
                case "NC": stateName = "North Carolina"; break;
                case "ND": stateName = "North Dakota"; break;
                case "OH": stateName = "Ohio"; break;
                case "OK": stateName = "Oklahoma"; break;
                case "OR": stateName = "Oregon"; break;
                case "PA": stateName = "Pennsylvania"; break;
                case "RI": stateName = "Rhode Island"; break;
                case "SC": stateName = "South Carolina"; break;
                case "SD": stateName = "South Dakota"; break;
                case "TN": stateName = "Tennessee"; break;
                case "TX": stateName = "Texas"; break;
                case "UT": stateName = "Utah"; break;
                case "VT": stateName = "Vermont"; break;
                case "VA": stateName = "Virginia"; break;
                case "WA": stateName = "Washington"; break;
                case "WV": stateName = "West Virginia"; break;
                case "WI": stateName = "Wisconsin"; break;
                case "WY": stateName = "Wyoming"; break;
                case "DC": stateName = "District of Columbia"; break;
                case "MH": stateName = "Marshall Islands"; break;
            }
            //console.log("statename:" + stateName);
            return stateName;
        },
        //not used?
        buildSearchUrlFromExternal: function (locationName, stateName) {
            var searchUrl = "";
            searchUrl = "/locations/us/" + window.GRGExtensions.searchUrlHelper.getUrlFriendlyLocationName(stateName) + "/" + window.GRGExtensions.searchUrlHelper.getUrlFriendlyLocationName(locationName) + "/";
            return searchUrl;
        },
        getUrlFriendlyLocationName: function (given_location) {
            var locationName = "";
            if (given_location !== "") {
                locationName = given_location.trim().replaceAll(" ", "-").replaceAll("\\.", "");
                locationName = locationName.replaceAll("é", "e").replaceAll("á", "a").replaceAll("í", "i").replaceAll("ó", "o").replaceAll("ú", "u"); 
                locationName = locationName.replaceAll("ü", "u").replaceAll("ñ", "n"); 
            }
            //alert(locationName);
            return locationName;
        },
        getUrlFriendlyStartEndDates: function (given_startDate, given_endDate) {
            var dates = "";
            if (given_startDate !== null && given_startDate !== "") {
                dates = "&start=" + given_startDate;
            }
            if (given_endDate !== null && given_endDate !== "") {
                dates += "&end=" + given_endDate;
            }
            return dates.replace("&", "?").replaceAll("/", "-");
        },
        //get human friendly location name
        getLocationNameFromUrl: function (given_location) {
            return given_location.replaceAll("-", " ").replaceAll("/", ", ");
        },
        getStartOrEndDateFromUrl: function (given_startDate) {
            var returnDate = "";
            if (given_startDate !== null && given_startDate !== "") {
                returnDate = given_startDate.replaceAll("-", "/");
            }
            return returnDate;
        }
    },
    getUrlParametersAsObject: function () {
        //currently only used in search page

        //replace hash with /[cityname]/
        //********************************/
        var url = window.location.href;
        url = url.substr(url.indexOf("/search/") + 8) + ""; //relative url now
        //get, remove city
        //var myCitySplit = url.split('/');
        if (url.indexOf("city=") < 0)
        {
            //console.log(url);
            var city = "";
            if (url.indexOf("/") > -1 && url.indexOf("?") !== 0) {
                city = url.substr(0, url.indexOf("/"));
                url = url.substr(url.indexOf("/") + 1);
                var province = "";
                var country = "";

                if (url.indexOf("/") > -1 && url.indexOf("?") !== 0) {
                    province = url.substr(0, url.indexOf("/"));
                    url = url.substr(url.indexOf("/") + 1);
                }
                if (url.indexOf("/") > -1 && url.indexOf("?") !== 0) {
                    country = url.substr(0, url.indexOf("/"));
                    url = url.substr(url.indexOf("/") + 1);
                }
                //replace below
                if (province !== "") {
                    city += "/" + province;
                }
                if (country !== "") {
                    city += "/" + country;
                }
            }
            url = "city=" + city + url;
        }
        url = url.replace("?", "&");
        //console.log("new url", url);
        //********************************/


        //var url = window.location.hash;
        var returnVal = null;
        if(url.length > 0) {
            returnVal = {};
            //url = url.substr(2);
            var rawUrlFilters = url.split("&");
            for (var i = 0; i < rawUrlFilters.length; i++) {
                if (rawUrlFilters[i] !== "") {
                    var line = rawUrlFilters[i].split("=");
                    if (line.length <= 2) {
                        //0 key, 1 value
                        var lval = line[1].split(",");
                        returnVal[line[0]] = lval;
                    }
                    else {
                        // 0 = master key name
                        //split others
                        //var masterKey = line
                        var myLineDataValues = rawUrlFilters[i].split("|");
                        myLineDataValues[0] = myLineDataValues[0].substr(line[0].length + 1);
                        returnVal[line[0]] = {};
                        for (var j = 0; j < myLineDataValues.length; j++) {
                            var subsetVal = myLineDataValues[j].split("=");
                            returnVal[line[0]][subsetVal[0]] = subsetVal[1];
                        }
                    }
                }
            }

            //change first & to ?
            if (url.indexOf("&") > -1){
                url = url.replace("&", "?");
            }

            //console.log("retval");
            //console.log(returnVal);
        }
        //console.log(returnVal);
        return returnVal;
    },
    scrollToElement: function (ele, options) {
        options = typeof options !== 'undefined' ? options : null;
        var _options = {
            headerHeight: null,
            offset: 10,
            afterElement: false,
            justCalculate: false,
            speed: 500
        };
        if (options !== null) {
            if (typeof options.headerHeight !== 'undefined' && options.headerHeight !== null) {
                _options.headerHeight = options.headerHeight;
            }
            if (typeof options.offset !== 'undefined' && options.offset !== null) {
                _options.offset = options.offset;
            }
            if (typeof options.afterElement !== 'undefined' && options.afterElement !== null) {
                _options.afterElement = options.afterElement;
            }
            if (typeof options.justCalculate !== 'undefined' && options.justCalculate !== null) {
                _options.justCalculate = options.justCalculate;
            }
            if (typeof options.speed !== 'undefined' && options.speed !== null) {
                _options.speed = options.speed;
            }
        }
        var screenHeight = $(window).height();
        var distanceFromTop = ($(ele).offset().top - $(window).scrollTop());
        var scrollAmount = distanceFromTop;
        var headerHeight = 0;

        if (_options.headerHeight !== null) {
            headerHeight = _options.headerHeight;
        }
        else {
            headerHeight = $("header").height();
        }

        //top
        scrollAmount -= headerHeight;
        scrollAmount -= _options.offset;
        if (_options.afterElement) {
            scrollAmount += $(ele).outerHeight();
        }
        //console.log("headerHeight: " + headerHeight + ", distanceFromTop: " + distanceFromTop + " $(ele).offset().top: " + $(ele).offset().top + ", $(window).scrollTop(): " + $(window).scrollTop());
        var scroll = "+=" + (scrollAmount + 0) + 'px';

        if (_options.justCalculate) {
            return scrollAmount;
        }
        else {
            $("html,body").animate({ scrollTop: scroll }, _options.speed);
        }
    },
    scrollToElementTop: function (ele, g_headerHeight) {
        g_headerHeight = typeof g_headerHeight !== 'undefined' ? g_headerHeight : null;
        var screenHeight = $(window).height();
        var distanceFromTop = ($(ele).offset().top - $(window).scrollTop());
        var scrollAmount = distanceFromTop;
        var headerHeight = 0;
        var offset = 10;

        if (g_headerHeight !== null){
            headerHeight = g_headerHeight;
        }
        else {
            headerHeight = $("header").height();
        }
        //console.log("headerHeight: " + headerHeight + ", distanceFromTop: " + distanceFromTop + " $(ele).offset().top: " + $(ele).offset().top + ", $(window).scrollTop(): " + $(window).scrollTop());

        scrollAmount -= headerHeight;
        scrollAmount -= offset;

        // now that we know how much of the element is visible
        // we can scroll down the appropriate amount to make the 
        // entire element visible to the user

        // If the height of the element is greater than the height of the 
        // viewport, then just scroll the distance from top,
        // otherwise scroll the scrollAmount to bring the entire element into view
        var scroll = "+=" + (scrollAmount + 0) + 'px';

        $("html,body").animate({ scrollTop: scroll }, 500);
    },
    scrollToElementBottom: function (ele) { /*unused*/
        var screenHeight = $(window).height();
        var distanceFromTop = ($(ele).offset().top - $(window).scrollTop());
        var scrollAmount = distanceFromTop;
        var headerHeight = $("header").height();
        var offset = 10;
        //scrollAmount -= headerHeight;
        scrollAmount -= offset;

        // now that we know how much of the element is visible
        // we can scroll down the appropriate amount to make the 
        // entire element visible to the user

        // If the height of the element is greater than the height of the 
        // viewport, then just scroll the distance from top,
        // otherwise scroll the scrollAmount to bring the entire element into view
        var scroll = "+=" + (scrollAmount - $(ele).outerHeight()) + 'px';

        $("html,body").animate({ scrollTop: scroll }, 500);
    },
    fixedOnScroll: function ($g_markerDom, $g_elementDom, g_offset, g_offset_bottom, g_minMaxScreenWidth, $g_domWidthToMatch, $g_endMarkerDom, g_relativeToParent){
        $g_endMarkerDom = typeof $g_endMarkerDom !== 'undefined' ? $g_endMarkerDom : null;
        g_relativeToParent = typeof g_relativeToParent !== 'undefined' ? g_relativeToParent : false;

        
        this.$markerDom = $g_markerDom;
        this.$elementDom = $g_elementDom;
        this.offset = g_offset;
        this.offset_bottom = g_offset_bottom;
        this.minMaxScreenWidth = g_minMaxScreenWidth; //negative values are max, positive values are min
        this.$domWidthToMatch = $g_domWidthToMatch;
        this.$endMarkerDom = $g_endMarkerDom;
        this.relativeToParent = g_relativeToParent;
        var self = this;
        

        this.headerHeight = $("header").height();
        this.currentWidth = $(window).width();
        if (self.minMaxScreenWidth === 0 ||
            (self.minMaxScreenWidth > 0 && this.currentWidth >= self.minMaxScreenWidth) ||
            (self.minMaxScreenWidth < 0 && this.currentWidth <= self.minMaxScreenWidth * -1)) {

            if (self.$domWidthToMatch !== null) {
                self.$elementDom.css("width", self.$domWidthToMatch.width());
            }
        }

        $(window).resize(function () {
            self.currentWidth = $(window).width();
            self.headerHeight = $("header").height();
            //if (self.currentWidth >= self.minScreenWidth) {
            if (self.minMaxScreenWidth === 0 ||
                (self.minMaxScreenWidth > 0 && self.currentWidth >= self.minMaxScreenWidth) ||
                (self.minMaxScreenWidth < 0 && self.currentWidth <= self.minMaxScreenWidth * -1)) {
                if (self.$domWidthToMatch !== null) {
                    self.$elementDom.css("width", self.$domWidthToMatch.width());
                }
            }
            else {
                self.$elementDom.css("width", "");
            }
            //reset px values, if fixed, set px, if not, set px
            if (self.$elementDom.hasClass("fixed")) {
                var offsetCalcFixed = (self.headerHeight + self.offset);
                self.$elementDom.css("top", (offsetCalcFixed) + "px");
            }
            else {
                //abs
                var offsetCalcNoFixed = self.offset;
                self.$elementDom.css("top", (offsetCalcNoFixed) + "px");
            }
            self.setPosition();
        });

        $(window).scroll(function () {
            self.setPosition();
        });
        
        //methods
        this.setPosition = function () {
            var self = this;
            var amtScrolled = $(window).scrollTop();
            //var posInlineNav = amtScrolled + $inlineNav.offset().top;
            //console.log(posInlineNav);
            //if (self.currentWidth >= self.minScreenWidth) {
            if (self.minMaxScreenWidth === 0 ||
                (self.minMaxScreenWidth > 0 && self.currentWidth >= self.minMaxScreenWidth) ||
                (self.minMaxScreenWidth < 0 && self.currentWidth <= self.minMaxScreenWidth * -1)) {
                if (amtScrolled + self.offset > self.$markerDom.offset().top - self.headerHeight) {
                    //console.log("setposlogic");
                    var offsetCalc = self.offset;
                    
                    if (self.$endMarkerDom !== null) {
                        //console.log((amtScrolled + headerHeight + offset + $elementDom.height()) + " vs " + $endMarkerDom.offset().top);
                        if ((!self.relativeToParent && (amtScrolled + self.headerHeight + self.$elementDom.outerHeight() + self.offset_bottom > self.$endMarkerDom.offset().top)) || (self.relativeToParent && (amtScrolled + self.headerHeight + self.$elementDom.outerHeight() + self.offset + self.offset_bottom > self.$endMarkerDom.offset().top))) {
                            //end reached
                            //console.log("abs");
                            //console.log("check " + (amtScrolled + self.headerHeight + self.$elementDom.outerHeight() + self.offset));
                            if (!self.$elementDom.hasClass("absolute")) {
                                self.$elementDom.removeClass("fixed").addClass("absolute");
                            }
                            if (!self.relativeToParent) {
                                offsetCalc = self.$endMarkerDom.offset().top - self.headerHeight - self.$elementDom.outerHeight() - self.offset_bottom;
                            }
                            else {
                                offsetCalc = self.$endMarkerDom.offset().top - self.$elementDom.outerHeight() - self.offset_bottom - self.$elementDom.parent().offset().top;
                                //console.log(offsetCalc);
                            }
                            self.$elementDom.css("top", (offsetCalc) + "px");
                        }
                        else {
                            //normal
                            if (!self.$elementDom.hasClass("fixed")) {
                                offsetCalc = (self.headerHeight + self.offset);
                                self.$elementDom.removeClass("absolute").addClass("fixed");
                                self.$elementDom.css("top", (offsetCalc) + "px");
                            }
                        }

                    }
                    else {
                        //standard
                        if (!self.$elementDom.hasClass("fixed")) {
                            offsetCalc = (self.headerHeight + self.offset);
                            self.$elementDom.removeClass("absolute").addClass("fixed");
                            self.$elementDom.css("top", (offsetCalc) + "px");
                        }
                    }


                }
                else {
                    self.$elementDom.removeClass("fixed").removeClass("absolute");
                    self.$elementDom.css("top", "0px");
                }
            }
            else {
                //straight up disabled
                self.$elementDom.removeClass("fixed").removeClass("absolute");
                self.$elementDom.css("top", "");
            }
        };
        this.init = function () {

        };

        //init hit
        self.setPosition();
    },
    dynamicSort: function(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        };
    },
    showModal: function (given_options) {
        //build bootstrap modal

        //buttons: array of html btns
        given_options.correctFixedDom = $("header");

        var options = {
            id: "",
            title: "",
            class: "",
            body: "",
            buttons: [],
            defaultCloseButton: true,
            modalSize: "",
            callback: null,
            callbackRendered: null,
            handleLoadingImgLoaded: false,
            destroyOnClose: false,
            correctFixedDom: null //if set, will set offset to avoid jump
        };
        if (typeof given_options !== 'undefined' || given_options !== null) {
            //get props
            if (typeof given_options.id !== 'undefined') {
                options.id = given_options.id;
            }
            if (typeof given_options.title !== 'undefined') {
                options.title = given_options.title;
            }
            if (typeof given_options.class !== 'undefined') {
                options.class = " " + given_options.class;
            }
            if (typeof given_options.body !== 'undefined') {
                options.body = given_options.body;
            }
            if (typeof given_options.modalSize !== 'undefined') {
                options.modalSize = given_options.modalSize;
                if (options.modalSize === "large") {
                    options.modalSize = "modal-lg";
                } else {
                    if (options.modalSize === "small") {
                        options.modalSize = "modal-sm";
                    }
                }
                options.modalSize = " " + options.modalSize;
            }
            if (typeof given_options.buttons !== 'undefined') {
                options.buttons = given_options.buttons;
            }
            if (typeof given_options.defaultCloseButton !== 'undefined' && given_options.defaultCloseButton !== "") {
                if ((given_options.defaultCloseButton && typeof given_options.buttons === 'undefined') || (given_options.defaultCloseButton && given_options.buttons.length === 0)) {
                    options.buttons = ['<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>'];
                }
            }
            if (typeof given_options.callback !== 'undefined') {
                options.callback = given_options.callback;
            }
            if (typeof given_options.callbackRendered !== 'undefined') {
                options.callbackRendered = given_options.callbackRendered;
            }
            if (typeof given_options.handleLoadingImgLoaded !== 'undefined') {
                options.handleLoadingImgLoaded = given_options.handleLoadingImgLoaded;
            }
            if (typeof given_options.destroyOnClose !== 'undefined') {
                options.destroyOnClose = given_options.destroyOnClose;
            }
            if (typeof given_options.correctFixedDom !== 'undefined' && given_options.correctFixedDom !== null) {
                options.correctFixedDom = given_options.correctFixedDom;
            }
        }
        var BSOptions = {
        };
        var loadingInit = "";
        if (options.handleLoadingImgLoaded) {
            //show loading
            loadingInit = " loading";
        }
        var modalHtml = "";

        modalHtml += '<div class="modal fade' + options.class + loadingInit + '" id="' + options.id + '" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">';
        modalHtml += '<div class="modal-dialog modal-dialog-centered' + options.modalSize + '" role="document">';
        modalHtml += '<div class="modal-content">';
        modalHtml += '<div class="modal-header">';
        if (options.title !== null && options.title !== ""){
            modalHtml += '<h5 class="modal-title" id="modalLabel">' + options.title + '</h5>';
        }
        modalHtml += '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" >&times;</span></button>';

        modalHtml += '</div>';
        modalHtml += '<div class="modal-body">';
        modalHtml += options.body;
        modalHtml += '</div>';
        modalHtml += '<div class="modal-footer">';
        if (options.buttons.length > 0){
            for (var i = 0; i < options.buttons.length; i++) {
                modalHtml += options.buttons[i];
            }
        }
        modalHtml += '</div>';
        modalHtml += '</div>';
        modalHtml += '</div>';
        modalHtml += '</div>';
        
        $("body").append(modalHtml);
        $('#' + options.id).modal(BSOptions);

        if (given_options.correctFixedDom !== null) {
            $(given_options.correctFixedDom).css("padding-right", $("body").css("padding-right"));
        }

        if (options.callback !== null) {
            options.callback();
        }
        if (options.callbackRendered !== null) {
            
            $('#' + options.id).on('shown.bs.modal', function (e) {
                options.callbackRendered();
            });

            if (options.handleLoadingImgLoaded) {
                //hide loading on img loaded
                $('#' + options.id).imagesLoaded(function () {
                    $('#' + options.id).removeClass("loading");
                });
            }
        }
        if (options.destroyOnClose) {
            $('#' + options.id).on('hidden.bs.modal', function (e) {
                $(this).remove();
            });
        }

        if (given_options.correctFixedDom !== null) {
            //position fixed header
            $('#' + options.id).on('hidden.bs.modal', function (e) {
                $(given_options.correctFixedDom).css("padding-right", "");
            });
        }
        //$('#' + id).modal('show');
        //$('#myModal').modal(BSOptions);
    },
    hideModal: function (id) {
        //hide bootstrap modal
        $('#' + id).modal('hide');
    },
    /*showLoginModal: function (redirUrl, featuredContent) {
        var msg = "You must be logged in to use this feature.";
        //build login form
        if (featuredContent !== ""){
            msg = featuredContent;
        }
        
        //bind
        window.GRGExtensions.showModal({
            id: "login-modal",
            title: "Create an account to add Favorites",
            class: "centered",
            body: msg,
            defaultCloseButton: true,
            modalSize: "medium"
        });
    },*/
    validateRedirDomain: function (allowedDomains, redirUrl) {
        var validDomain = false;
        //console.log("validating urls::" + allowedDomains + " give url of " + redirUrl);

        //redirUrl = decodeURIComponent(redirUrl); // decode urls before sending in to this function
        var arrayAllowedDomains = [];
        if (allowedDomains !== null && allowedDomains !== "") {
            arrayAllowedDomains = allowedDomains.split(',');
        }
        for (var i_domindex = 0; i_domindex < arrayAllowedDomains.length; i_domindex++) {
            if (window.GRGExtensions.getDomain(arrayAllowedDomains[i_domindex]) === window.GRGExtensions.getDomain(redirUrl)) {
                validDomain = true;
            }
        }
        if ((redirUrl.indexOf("/") === 0) &&
            (redirUrl.indexOf("//") !== 0)) {
            validDomain = true;
        }
        //console.log("valid domain? " + validDomain);
        return validDomain;
    },
    formsHelper: {
        checkEmailExists: function (response) {
            var ResponseObject = JSON.parse(response);
            
            //alert(ResponseObject.Success);
            if (ResponseObject.Success) {
                //alert(ResponseObject.Data);
                if (ResponseObject.Data === "true"){
                    return '"true"';
                }
                else {
                    return '"That email is already in use"';
                }
            }
            else {
                //show errror
                var msg = "";
                if (ResponseObject.Messages.length > 0) {
                    for (var i = 0; i < ResponseObject.Messages.length; i++){
                        msg += ResponseObject.Messages[i] + "<br />";
                    }
                }
                console.log(msg);
                /*window.GRGExtensions.showModal({
                    id: "error-modal",
                    title: "Error",
                    class: "centered",
                    body: msg,
                    defaultCloseButton: true,
                    modalSize: "small"
                });*/
                //Response.Messages
            }
            return '"true"';
        },
        bindPasswordHideShow: function (elementid, locationid) {
            var $element = $("#" + elementid);
            var $location = $("#" + locationid);
            $location.after("<a href=\"#showpassword\" class=\"show-hide-password show\" data-for=\"" + elementid + "\"><span class=\"show\">Show</span><span class=\"hide\">Hide</span></a>");
            //binder
            $("a.show-hide-password").click(function (e) {
                e.preventDefault();
                var $self = $(this);
                if ($self.attr("data-for").length > 0)
                {
                    var id = $self.attr("data-for");
                    var $target = $("#" + elementid);
                    if ($target.attr("type") === "password")
                    {
                        $target.attr("type", "text");
                        $self.removeClass("show");
                        $self.addClass("hide");
                    }
                    else {
                        $target.attr("type", "password");
                        $self.addClass("show");
                        $self.removeClass("hide");
                    }
                    
                }
            });
        },
        passwordHintService: {
            bind: function (fieldName, frm) {

                var fieldElement = $('[name="' + fieldName + '"]', frm);
                //console.log(fieldElement);
                if (!fieldElement.length) return;

                var fieldId = $(fieldElement).attr('id');
                
                var passwordContainer = $('[data-field-container="' + fieldId + '"]', frm);
                //console.log(passwordContainer);
                if (!passwordContainer.length) return;

                var hintContainer = $('[data-hintfor="' + fieldId + '"]', passwordContainer);

                var hintSteps = [
                    $('[data-hintstep="1"]', hintContainer),
                    $('[data-hintstep="2"]', hintContainer),
                    $('[data-hintstep="3"]', hintContainer),
                    $('[data-hintstep="4"]', hintContainer),
                    $('[data-hintstep="5"]', hintContainer)
                ];

                var UpperCaseRegexp = new RegExp(/[A-Z]/);
                var LowerCaseRegexp = new RegExp(/[a-z]/);
                var NumberRegexp = new RegExp(/[0-9]/);
                var SpecialRegexp = new RegExp(/[\.,_\-!@#$%^&*\(\)=+\\|\[\];:'"<>\/\?{}]/);

                $('#' + fieldId, passwordContainer).on('keyup', function (e) {
                    var Password = $(this).val();
                    if (Password.length >= 8) hintSteps[0].addClass('pass');
                    else hintSteps[0].removeClass('pass');

                    if (Password.match(UpperCaseRegexp) !== null) hintSteps[1].addClass('pass');
                    else hintSteps[1].removeClass('pass');

                    if (Password.match(LowerCaseRegexp) !== null) hintSteps[2].addClass('pass');
                    else hintSteps[2].removeClass('pass');

                    if (Password.match(NumberRegexp) !== null) hintSteps[3].addClass('pass');
                    else hintSteps[3].removeClass('pass');

                    if (Password.match(SpecialRegexp) !== null) hintSteps[4].addClass('pass');
                    else hintSteps[4].removeClass('pass');

                });
                
            }
        }
    },
    cookiesHelper: {
        getCookie: function (cookieName) {
            return Cookies.get(cookieName);
        },
        setCookie: function (cookieName, data) {
            Cookies.set(cookieName, data);
        }
    },
    favoritePropertiesHelper: {
        myFavs: [], //temp page level list
        populateFavorites: function () { //get only guids
            if (window.GRGExtensions.isLoggedIn()) {
                PropertyDetailService.getFavoriteProperties(null).done(function (res) {
                    if (res.Success) {
                        //self.results.data = res.Data;
                        console.log("raw favorites:");
                        console.log(res.Data);

                        //populate global property, so we dont have each element hitting the server.
                        //alternative is temp cookie, I don't see the need
                        window.GRGExtensions.favoritePropertiesHelper.myFavs = res.Data;

                        //when done, fire event letting peeps know.
                        window.GRGEvents.SIG_FavoritedPropertiesChanged.dispatch();
                    }
                    else {
                        //alert("There was an unsuccess error");
                        console.log("There was an unsuccess error retrieving your favorite properties.");
                        console.log(res);

                    }
                }).fail(function (e) {
                    //alert("There was an error");
                    console.log("We have encountered an error retrieving your favorite properties.");
                    console.log(e);

                    /*var msg = "We have encountered an error retrieving your favorite properties.";
                    if (typeof e.responseJSON !== 'undefined' && typeof e.responseJSON.Message !== 'undefined' && typeof e.responseJSON.ExceptionMessage !== 'undefined') {
                        msg += "<div><code>" + e.responseJSON.Message + "<br />" + e.responseJSON.ExceptionMessage + "</code></div>";
                    }
        
                    window.GRGExtensions.showModal({
                        id: "error-modal",
                        title: "Error",
                        class: "centered",
                        body: msg,
                        defaultCloseButton: true,
                        modalSize: "small"
                    });*/
                });
            }
            else {
                //console.log("not logged in. dispatched.");
                //window.GRGExtensions.favoritePropertiesHelper.myFavs = [];
                window.GRGEvents.SIG_FavoritedPropertiesChanged.dispatch();
            }
        }
    },
    setDateTimePickerYearAriaLabel: function () {
        setTimeout(function () {
            $("select.ui-datepicker-year").attr("aria-label", "Select Year");
        }, 10);
    },
    expandBannersWithForm: function ($doms) {
        $doms.each(function () {
            var $banner = $(this).find(".banner-container");
            var windowWidth = window.innerWidth;
            var bannerHeight = 545;//$banner.children(".content-container").height();
            var bannerHeightMobile = 350;
            var $content = $banner.find(".content .text-content-holder");
            var contentHeight = $content.height();
            var $form = $banner.find(".desktop-form-column .form-panel");
            var $formHeight = $form.height() + 30;
            if ($formHeight > contentHeight) {
                contentHeight = $formHeight;
            }
            var bufferOffset = 75; //max overlap px
            var buffer = -15; //applied to difference
            if (windowWidth > 991) {
                if (contentHeight - bufferOffset > bannerHeight) {
                    var adjusted = contentHeight + buffer;
                    $banner.css('min-height', adjusted + 'px');
                }
                else {
                    $banner.css('min-height', "");
                }
            }
            else {
                //just check content
                if (contentHeight - bufferOffset > bannerHeightMobile) {
                    var adjustedMobile = contentHeight + buffer;
                    $banner.css('min-height', adjustedMobile + 'px');
                }
                else {
                    $banner.css('min-height', "");
                }
            }
        });
    },
    isLoggedIn: function () {
        if ($("body").hasClass("logged-in")) {
            return true;
        }
        else {
            return false;
        }
    },
    getDomain: function (url) {
        var protocol_i = url.indexOf('://');
        
        var domain = "";
        if (protocol_i > -1)
        {
            var front_parsed_url = url.substr(0, protocol_i);
            var temp = url.substr(protocol_i + 3);
            var endslash_i = temp.indexOf("/");
            if (endslash_i > -1){
                domain = temp.substr(0, temp.indexOf("/"));
            }
            else {
                domain = temp;
            }
            domain = front_parsed_url + "://" + domain;
        }
        return domain;
    }
    /*DateRangePicker: {
        $inlineDatePickerDom: null,
        $dateMode: "double", //single = date range goes into single field, double = separate inputs
        $startDateDom: null,
        $endDateDom: null,
        init: function () {
            //bind 
            //logic is to 


        }
    }*/
};
String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};
