/*marathonApp.controller('CarouselController', ['$scope', '$sce', '$window', 'Helpers', function ($scope, $sce, $window, Helpers) {
    $scope.id = "carousel" + Helpers.guid().replace(/-/g, "");
    $scope.gallery;
    var currentIndex;
    var lastIndex;

    //Use ng-init to pass the server-side code into our controller
    $scope.init = function (json) {

        $scope.gallery = new Carousel(JSON.parse(json));

        $(function () {

            $scope.gallery.Current = $('#' + $scope.id);
            $scope.gallery.Modal = $('[data-carousel]').find('.modal');
            //displayModal(true);
            $scope.gallery.Current.carousel();

            var cHeight = 0;
            //Fires immediately when slide instance method is invoked
            $scope.gallery.Current.on('slide.bs.carousel', function (e) {

                var $nextImage = $(e.relatedTarget).find('img');

                $activeItem = $('.active.item', this);

                // prevents the slide decrease in height
                if (cHeight == 0) {
                    cHeight = $(this).height();
                    $activeItem.next('.item').height(cHeight);
                }

                // prevents the loaded image if it is already loaded
                var src = $nextImage.data('lazy-load-src');

                if (typeof src !== "undefined" && src != "") {
                    $nextImage.attr('src', src)
                    $nextImage.data('lazy-load-src', '');
                }
            });

            //Fires when the carousel has completed its slide transition
            $scope.gallery.Current.on('slid.bs.carousel', function (e) {

            });

            //Stop the carousel from cycling when the modal is closed
            $('#modal_' + $scope.id).on('hide.bs.modal', function (e) {
                cycleCarousel(false);
            });
        });
    }

    $scope.openCarousel = function (idx) {
        displayModal(true);
        $scope.changeSlide(idx);
    }

    $scope.previousSlide = function ($event) {
        $event.preventDefault();
        $scope.gallery.Current.carousel('prev');
    }

    $scope.nextSlide = function ($event) {
        $event.preventDefault();
        $scope.gallery.Current.carousel('next');
    }

    $scope.changeSlide = function (idx) {
        $scope.gallery.Current.carousel(idx);
    }

    var displayModal = function(showModal) {
        if (showModal) $scope.gallery.Modal.modal('show');
        else $scope.gallery.Modal.modal('hide');
    }

    var cycleCarousel = function(beginCycle) {
        if (beginCycle) $scope.gallery.Current.carousel('cycle');
        else $scope.gallery.Current.carousel('pause');
    }

    var carouselSlid = function () {

    }

    var carouselSlide = function () {
      
    }

}]);
*/