//Extend jQuery to allow grabbing URL parameters
//Sample code: var allUrlParams = $.getUrlVars(); var nameUrlParam = $.getUrlVar('name')
$.extend({
    getUrlVars: function () {
        var vars = [], hash;
        var currentUrl = window.location.href.toLowerCase();
        var hashes = currentUrl.slice(currentUrl.indexOf('?') + 1).split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    },
    getUrlVar: function (name) {
        name = name.toLowerCase();
        return $.getUrlVars()[name];
    },
    parseUrlVars: function (url) {
        url = url.toLowerCase();
        var vars = [], hash;
        var hashes = url.slice(url.indexOf('?') + 1).split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    },
});