/*marathonApp.controller('SampleController', ['$scope', '$sce', 'UrlService', '$location', '$timeout', function ($scope, $sce, UrlService, $location, $timeout) {

    $scope.items = []; //this will receive our response

    //ng-int function that takes in the ID of the ipublished content from the razor view 
    $scope.init = function (id) {

        var endpoint = '/umbraco/surface/TeamMemberSurface/Get';

        UrlService.GetData(endpoint, id).then(function (response) {
            $scope.items = angular.copy(response.Filters);
        });
    };

    $scope.$watch('variableName', function (nv, ov) {
        // return out of the func early if the old value equals the new value after the digest cycle
        if (nv === ov) return;

        //Normal watch code goes here

        $scope.$$postDigest(function () {
            //run this code as a callback after the current digest cycle completes
        });

    }, true); //true sets a 'deep' watch

}]);*/