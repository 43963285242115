// Implementation Examples //
// https://github.com/millermedeiros/js-signals/wiki/Examples //

(function ($, Signal) {
    window.GRGEvents = {
        
        SIG_GoogleMapsLoaded: new Signal(),
        SIG_FavoritedPropertiesChanged: new Signal(),
        SIG_FAQSearchResultClicked: new Signal(),
        SIG_FAQSearchAutocompleteOpen: new Signal(),
    };
})(jQuery, signals.Signal); 