//Set up pseudoclassical pattern to attach methods to objects prototype and have the constructor included automatically
/*
var Carousel = function (json) {
    this.Slides;
    this.TransitionSpeed;
    this.ShowSlideIndicators;
    this.Index;
    this.Current;
    this.Modal;
    this.Players = Array();

    for (var prop in json) {
        if (json.hasOwnProperty(prop)) this[prop] = json[prop];
    }
}*/