/*marathonApp.factory('Helpers', [function () {
    //Helper service
    var o = {
        Helpers: []
    };

    o.guid = function() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    //Other helpers can be added here

    return o;
}]);*/